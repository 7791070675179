<script>
import isEqual from 'lodash-es/isEqual'
import { ValidationObserver } from 'vee-validate'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import UserSelector from '/~rec/components/users/user-selector.vue'
import Hi5PointsGifting from '/~rec/components/hi5/hi5-points-gifting.vue'
import FormElementLabel from '/~rec/components/form/form-element-label.vue'
import { useRecProfile } from '/~rec/composables/profile'
import { useForm } from '/~/composables/base/use-form'

export default {
  name: 'recognition-form',
  components: {
    BaseTextarea,
    BaseButton,
    UserSelector,
    BaseLoader,
    Hi5PointsGifting,
    FormElementLabel,
    BaseIcon,
    ValidationObserver,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    nomineeLabel: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    headerLabel: {
      type: String,
      default: '',
    },
    giftLabel: {
      type: String,
      default: '',
    },
    submitLabel: {
      type: String,
      default: 'Nominate',
    },
    nominee: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
      validator: (v) => !v || /hi|award|perk/.test(v),
    },
    filterUsers: {
      type: Function,
      default: (v) => v,
    },
    userId: {
      type: String,
      default: null,
    },
    backendErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { features } = useRecProfile()
    const { validationObserverRef } = useForm()

    return {
      features,
      ui,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {
        nominee: this.nominee,
        reason: '',
        selectedPoints: 0,
      },
      formOrigin: {
        nominee: this.nominee,
        reason: '',
        selectedPoints: 0,
      },
      otherPointsError: false,
    }
  },
  computed: {
    disabled() {
      return (
        !this.form.nominee ||
        !this.form.reason ||
        (!this.form.selectedPoints && this.form.selectedPoints !== 0) ||
        this.otherPointsError
      )
    },
    isRemovableNominee() {
      return !this.success && !this.submitting && !this.userId
    },
    isShowPointsBlock() {
      return this.features.points && this.type === 'hi5'
    },
  },
  watch: {
    nominee(value) {
      if (value) {
        this.form.nominee = value
        this.formOrigin.nominee = value
      }
    },
    form: {
      deep: true,
      handler() {
        const equal = isEqual(this.form, this.formOrigin)

        this.$route.params.showConfirm = !equal
      },
    },
  },
  created() {
    this.clearConfirm()
  },
  destroyed() {
    this.clearConfirm()
  },
  methods: {
    nominate() {
      this.$emit('nominate', this.form)
    },
    clearConfirm() {
      delete this.$route.params.showConfirm
    },
    goBack() {
      this.clearConfirm()
      this.$emit('close')
    },
    setSelectedPoints(value) {
      this.form.selectedPoints = value
    },
    setOtherPointsError(value) {
      this.otherPointsError = value
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <div>
      <base-loader v-if="loading" class="absolute inset-0" fullwidth />

      <div v-if="!loading" class="space-y-2.5 pb-[5px] md:pb-0">
        <base-icon
          v-if="success"
          class="mx-auto my-0"
          svg="v3/success"
          :size="250"
        />

        <h2
          :class="{
            'w-full text-center': success,
            'text-base leading-6': ui.mobile,
          }"
          class="text-eonx-neutral-800"
        >
          {{ headerTitle }}
        </h2>

        <div
          v-if="!success"
          :class="{
            'text-eonx-neutral-800': ui.desktop,
            'text-base leading-6 text-eonx-neutral-600': ui.mobile,
          }"
        >
          {{ headerLabel }}
        </div>

        <div class="flex flex-col">
          <form-element-label
            :label="giftLabel"
            icon="rec/award"
            :size="24"
            class="mt-2.5"
          />

          <div
            class="mt-2.5 truncate rounded-xl bg-gray-50 p-[5px] xxs:p-[15px] md:bg-white"
          >
            <div class="flex items-center truncate">
              <div
                class="mr-[5px] h-10 w-10 shrink-0 xxs:mr-2.5 xxs:h-12 xxs:w-12"
              >
                <slot name="gift-img" />
              </div>
              <div class="truncate">
                <div
                  class="mb-0.5 truncate font-bold leading-tight text-eonx-neutral-800"
                >
                  <slot name="gift-title" />
                </div>
                <div class="flex flex-wrap truncate leading-tight">
                  <slot name="gift-subtitle" />
                </div>
              </div>
            </div>
          </div>

          <form-element-label
            :label="nomineeLabel"
            icon="rec/user-outline"
            :size="24"
            class="mb-2.5 mt-5"
          />

          <user-selector
            :filter-users="filterUsers"
            :user="nominee"
            :removable="isRemovableNominee"
            tooltip-position="bottom"
            @user-select="form.nominee = $event"
          />

          <form-element-label
            label="Message"
            icon="rec/annotation-outline"
            :size="24"
            class="mt-5"
          />

          <base-textarea
            v-model="form.reason"
            :validation="{
              rules: 'required',
              name: 'Message',
            }"
            class="mb-0 w-full grow-0"
            :disabled="submitting || success"
            :max-rows="6"
            :error="backendErrors.reason"
            placeholder="Type your message here..."
            :maxlength="300"
            required
            name="reason"
            look="recroom"
          />
          <div v-if="isShowPointsBlock" class="mb-[15px] md:mb-6">
            <hi5-points-gifting
              :disabled="submitting"
              :success="success"
              @selected-points="setSelectedPoints"
              @other-points-error="setOtherPointsError"
            />
          </div>

          <slot />

          <div
            class="grid w-full justify-items-center md:mb-5"
            :class="{
              'absolute bottom-0 left-0 z-20 w-full bg-white px-5 pb-[15px] pt-5':
                !ui.desktop,
            }"
          >
            <base-button
              v-if="!success"
              :disabled="disabled || submitting"
              :loading="submitting"
              class="my-0 w-full sm:w-48"
              @click="handleSubmit(nominate)"
            >
              {{ submitLabel }}
            </base-button>
            <base-button v-else class="my-0 w-full sm:w-48" @click="goBack()">
              Got it
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>
