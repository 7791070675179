<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseToggle from '/~/components/base/toggle/base-toggle.vue'
import { formatPoints } from '/~/utils/points'
import RecPoints from '/~/components/app/header/app-header-rec-points.vue'
import RecognitionTokens from '/~rec/components/recognition/recognition-tokens.vue'
import { useRecProfile } from '/~rec/composables/profile'

const arrayPoints = [
  {
    id: 0,
  },
  {
    id: 50,
  },
  {
    id: 100,
  },
  {
    id: 250,
  },
  {
    id: 500,
  },
  {
    id: null,
  },
]

export default {
  name: 'hi5-points-gifting',
  components: {
    BaseIcon,
    BaseInput,
    BaseButton,
    BaseToggle,
    RecPoints,
    RecognitionTokens,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      currency,
      profile,
      managementWalletBalance,
      isMeAdmin,
      isMeSuperAdmin,
    } = useRecProfile()

    return {
      managementWalletBalance,
      isMeSuperAdmin,
      formatPoints,
      isMeAdmin,
      currency,
      profile,
      ui,
    }
  },
  data() {
    return {
      selectedPoints: 0,
      arrayPoints,
      isShowOther: false,
      otherPts: null,
      transitionStart: false,
      error: false,
      toggleSettings: true,
    }
  },
  computed: {
    description() {
      if (this.toggleSettings) {
        return this.selectedPoints === 0
          ? 'Gift some of your Points for this Hi-5'
          : 'Choose to gift some of your Points with the Hi-5'
      }
      return 'Gift the recipient with Points from your Management Wallet'
    },
    ids() {
      return this.currentArrayPoints.map((i) => i.id)
    },
    isShowOtherButton() {
      return (
        (!this.isShowOther && !this.otherPts && this.selectedPoints !== null) ||
        (!this.isShowOther && this.ids.includes(Number(this.otherPts)))
      )
    },
    isShowOtherSelected() {
      return Boolean(
        !this.isShowOther &&
          this.selectedPoints &&
          !this.ids.includes(this.selectedPoints)
      )
    },
    isDesktop() {
      return ui.desktop
    },
    currentArrayPoints() {
      return this.toggleSettings
        ? this.arrayPoints.map((item) => {
            if (item.id || item.id === 0) {
              return {
                ...item,
                label: `${item.id} PTS`,
              }
            }
            return {
              ...item,
              label: 'Other',
            }
          })
        : this.arrayPoints.map((item) => {
            if (item.id || item.id === 0) {
              return {
                id: item.id * 10,
                label: `${item.id * 10} PTS`,
              }
            }
            return {
              ...item,
              label: 'Other',
            }
          })
    },
    isAdmin() {
      return this.isMeAdmin || this.isMeSuperAdmin
    },
    balance() {
      return this.isAdmin && !this.toggleSettings
        ? this.managementWalletBalance
        : this.profile.tokenBalance
    },
    walletType() {
      return this.toggleSettings ? 'personal' : 'management'
    },
  },
  watch: {
    otherPts(value) {
      if (value !== null && value <= this.balance) {
        this.error = false
        this.$emit('selected-points', {
          points: Number(value),
          walletType: this.walletType,
        })
      }
      if (value > this.balance) {
        this.error = true
      }
    },
    toggleSettings() {
      this.selectedPoints = 0
      this.otherPts = null
      this.isShowOther = false
      setTimeout(() => {
        this.transitionStart = false
      })
      this.$emit('selected-points', {
        points: 0,
        walletType: this.walletType,
      })
    },
    error(value) {
      this.$emit('other-points-error', value)
    },
  },
  methods: {
    selectPts(value) {
      if (value === null && !this.isDesktop) {
        this.openModal()
        return
      }

      if (this.otherPts) this.otherPts = null
      if (value !== null && value > this.balance) return
      if (value === null && this.isDesktop) {
        this.isShowOther = true
        setTimeout(() => {
          this.transitionStart = true
        })
      }
      if (value !== null && this.isShowOther) {
        setTimeout(() => {
          this.transitionStart = false
        })
        this.isShowOther = false
        if (this.error) {
          this.error = false
        }
      }
      this.selectedPoints = value
      this.$emit('selected-points', {
        points: this.selectedPoints,
        walletType: this.walletType,
      })
    },
    setOtherPts() {
      if (!this.otherPts) return
      if (this.error) this.error = false
      this.selectedPoints = Number(this.otherPts)
      setTimeout(() => {
        this.transitionStart = false
      })
      this.isShowOther = false
      this.$emit('selected-points', {
        points: this.selectedPoints,
        walletType: this.walletType,
      })
    },
    openModal() {
      modal.show('rec-hi5-points-gifting', {
        props: {
          current: this.otherPts,
          balance: {
            type: this.walletType,
            points: this.formatPoints(this.balance),
          },
        },
        on: {
          selected: (value) => {
            this.otherPts = Number(value)
            this.setOtherPts()
          },
        },
      })
    },
  },
}
</script>

<template>
  <div class="w-full">
    <div class="flex h-6 flex-wrap content-center text-amber-400 md:mb-[15px]">
      <div class="mr-2.5">
        <base-icon class="text-amber-400" svg="points/token" :size="24" />
      </div>
      <div class="float-left font-bold">
        <span>Points gifting</span>
      </div>
    </div>
    <div
      class="flex h-11 items-center leading-5 text-eonx-neutral-600 md:hidden"
      :class="{
        'my-[5px]': isAdmin,
      }"
    >
      {{ description }}
    </div>
    <div class="rounded-xl bg-white md:px-6 md:py-[15px]">
      <div v-if="success && (otherPts || selectedPoints)" class="w-72">
        <recognition-tokens
          :nominal="otherPts || selectedPoints"
          label="sent with this Hi-5"
          color="gold"
          class="rounded-md px-6 py-2.5"
        />
      </div>
      <template v-else>
        <div
          v-if="isAdmin"
          class="space-4 flex flex-row items-center justify-between md:justify-start"
        >
          <div class="mr-0 leading-5 text-eonx-neutral-600 md:mr-6">
            Change to sending your
            {{ toggleSettings ? 'management' : 'personal' }}
            Points
          </div>
          <base-toggle v-model="toggleSettings" :disabled="disabled" />
        </div>
        <rec-points
          class="my-2.5 flex w-full justify-end md:hidden"
          :show-personal="toggleSettings"
          :show-management="!toggleSettings"
        />
        <div
          class="hidden text-eonx-neutral-600 md:block"
          :class="{
            'my-2.5': isAdmin,
          }"
        >
          {{ description }}
        </div>
        <ul class="mb-[5px] flex overflow-x-auto">
          <li
            v-for="point in currentArrayPoints"
            :key="point.id"
            class="mb-[15px] mr-2.5 mt-2.5 md:mb-0"
          >
            <div v-if="point.id === null">
              <div
                v-if="isShowOther"
                class="relative h-16 transition-width duration-300 ease-in-out"
                :class="{
                  'w-40': transitionStart,
                  'w-0': !transitionStart,
                }"
              >
                <base-input
                  v-model="otherPts"
                  placeholder="PTS amount"
                  input-class="pt-0"
                  class="points-input"
                  :nolabel="true"
                  look="box"
                  :autofocus="true"
                  :mask="{
                    mask: /^(?!0)\d+?$/,
                  }"
                />
                <div
                  class="z-100 absolute left-full top-0 flex h-full rounded-r-lg px-1.5 text-white"
                  :class="{
                    'bg-green-300': otherPts && !error,
                    'bg-gray-300': !otherPts && !error,
                    'bg-red-400': error,
                  }"
                  :style="{ left: '80%' }"
                >
                  <base-button
                    v-if="!error"
                    icon="rec/check-mark"
                    icon-size="xl"
                    size="xl"
                    :disabled="!otherPts || disabled"
                    class="text-white"
                    @click="setOtherPts"
                  />
                  <base-button
                    v-else
                    icon="rec/close"
                    icon-size="xl"
                    size="xl"
                    :disabled="true"
                  />
                </div>
              </div>
              <button
                v-if="isShowOtherButton"
                :disabled="disabled"
                class="transiotion-all h-16 rounded-lg text-center text-eonx-neutral-800 duration-300 ease-in-out"
                :class="{
                  'bg-amber-400': selectedPoints === point.id,
                  'bg-gray-50': selectedPoints !== point.id,
                  'cursor-pointer': point.id < balance || point.id === null,
                  'text-gray-300': point.id > balance && point.id !== null,
                  'w-40': transitionStart,
                  'w-[72px]': !transitionStart,
                }"
                @click="selectPts(point.id)"
              >
                {{ point.label }}
              </button>
              <button
                v-if="isShowOtherSelected"
                :disabled="disabled"
                class="transiotion-all h-16 min-w-[72px] truncate rounded-lg px-[5px] text-center text-white duration-300 ease-in-out"
                :class="{
                  'w-40': transitionStart,
                  'w-full': !transitionStart,
                  'bg-amber-400': toggleSettings,
                  'bg-violet-400': !toggleSettings,
                }"
                @click="selectPts(null)"
              >
                <p class="leading-5">
                  {{ `${selectedPoints} PTS` }}
                </p>
                <p class="text-sm leading-5">Edit</p>
              </button>
            </div>
            <button
              v-else
              :disabled="disabled || balance < point.id"
              class="h-16 w-[72px] rounded-lg text-center text-sm"
              :class="{
                'bg-amber-400': selectedPoints === point.id,
                'bg-violet-400': selectedPoints === point.id && !toggleSettings,
                'text-eonx-neutral-800':
                  selectedPoints !== point.id && point.id <= balance,
                'text-white': selectedPoints === point.id,
                'bg-gray-50': selectedPoints !== point.id,
                'text-gray-300': point.id > balance && point.id !== null,
                'cursor-default': point.id > balance,
              }"
              @click="selectPts(point.id)"
            >
              {{ point.label }}
            </button>
          </li>
        </ul>
        <template v-if="isAdmin">
          <div v-if="toggleSettings" class="mt-2.5 flex">
            <base-icon
              class="mr-2.5 text-white"
              svg="points/warning"
              :size="24"
            />
            <p class="text-orange-700">
              You are about to gift your own personal points
            </p>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.points-input {
  .base-field__entry {
    height: 62px !important;
  }
}
</style>
